input[type="checkbox"]:checked{
  accent-color: #661c69;
}

.policyContainer {
  display: flex;
  width: 580px;
  height: 490px;
  padding: 40px;
  margin-top: 10rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  background: #FFF;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.10);
}

.btn_continue_terms {
  border-radius: 5px;
}

.wrapper {
  display: flex;
  gap: 40px;
  margin-top: 30px;
  justify-content: space-around;
  background-color: #FFFFFF;
}

.input_label{
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.welcomeHeadingBox {
  // position: absolute;
  // width: 308.78px;
  height: 114px;
  // left: 969px;
  // top: 920px;
  border-bottom: 1px solid #909090;
}

.welcomeHeading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'TTNormsRegular', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.accountUnlocked {
  margin-top: 20px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center
}

.accountUnlockedMessage {
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.welcomeHeadingSmall {
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
}

.privacyPolicy {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 20px;
}

.otpchoiceinputlabel {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.customInput {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.customInput>input {
  border: 1px solid #DDDDDD;
  border-bottom: 1px solid #999999;
  padding: 14px 12px;
  color: black;
  height: 10px;
}

.passwordInput {
  border: 1px solid #909090;
  border-radius: 5px;
  width: 100%;
  height: 50px;
}

.passwordInput > input {
  padding: 16px 6px 6px;
  color: black;
  height: 28px;
}

.radioColor {
  color: #F5821F !important;
}

.container {
  // margin: 2rem auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 26.5rem;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 3px 6px #0000001c;
  // border: 1px solid #f3f3f3;
  box-sizing: border-box;
  padding: 20px;
  border-bottom: 1px solid #909090;
}

.middle_heading {
  font-family: 'TTNormsRegular', Arial, Helvetica, sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  flex-grow: 1;
}

.btn_wrapper {
  margin-top: 36px;
  display: flex;
  column-gap: 20px;
}

.trouble_otpchoice_btn_wrapper {
  // min-height: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.inlineAlert{
  display: flex;
  color: #D71721;
  column-gap: 5px;
}

.modal {
  color: black;
  // height: 270px;
  width: 450px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 23px;
  gap: 20px;
  box-shadow: 0px 4px 12px rgba(62, 64, 70, 0.08);
  border-radius: 10px;
}

.fismodal_button {
  width: 100px;
  height: 30px;
  background: #661c69;
  border-radius: 56px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
}

.fismodal_button:hover{
  background: #661c69;
}

.invalidOtp {
  display: flex;
  // border: 1px solid #FD0000;
  color: #D71721;
  padding: 10px;
  margin-top: 5px;
  // border-radius: 3px;
  // width: 270px;
}

.successAlert {
  display: flex;
  border: 1px solid #1976D2;
  color: #444444;
  background: rgba(25, 118, 210, 0.1);
  padding: 8px 12px;
  margin-top: 5px;
  border-radius: 5px;
  // width: 270px;
}

.login_button{
  width: 100%;
  height: 45px;
  background: #661c69;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
}

.login_button:hover {
  background: #ffffff;
  color: #661c69;
  border: 1px solid #661c69;
}

.continue_button {
  width: 120px;
  height: 30px;
  background: #661c69;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
}

.continue_button:hover {
  background: #661c69;
}

.button_cancel {
  width: 100px;
  height: 30px;
  border: 4px solid #661c69;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #661c69;
  text-transform: none;
  background: #ffffff;
}

.button_cancel:hover {
  background: #ffffff;
  border-color: #661c69;
}

.heading {
  font-family: 'TTNormsRegular', Arial, Helvetica, sans-serif;
  // width: 481px;
  font-size: 30px;
  font-weight: 700;
  // text-align: center;
  flex-grow: 1;
}

.heading_verification {
  font-family: 'TTNormsRegular', Arial, Helvetica, sans-serif;
  // width: 481px;
  line-height: 40px;
  font-size: 22px;
  font-weight: 700;
  flex-grow: 1;
}
.subHeading {
  text-align: center;
  margin-top: 20px;
}

.boxMarginTop {
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
}

.label_title {
  margin-bottom: 5px;
  color: #444444;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  display: inline;
}

.link_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.select_inputs {
  background: #ffffff;
  opacity: 1;
  height: 48px;
  box-sizing: border-box;
  font: normal normal 300 14px/16px Arial, serif;
  letter-spacing: 0.1px;
  text-align: left;
}

.select_inputs_placeholder {
  background: #ffffff;
  height: 48px;
  box-sizing: border-box;
  font: normal normal 300 14px/16px Arial, serif;
  letter-spacing: 0.1px;
  text-align: left;
}

.select_inputs svg {
  color: #661c69;
}

.select_inputs .MuiOutlinedInput-input {
  padding: 11.5px 14px;
}

.select_inputs fieldset {
  border-radius: 6px;
  border: 1px solid #e4e9f2;
}

.select_inputs_error fieldset {
  border-radius: 6px;
  border: 1.6px solid #fd0000;
}

.select_style {
  background: #ffffff;
  opacity: 1;
  /* height: 48px; */
  box-sizing: border-box;
  font: normal normal 300 14px/16px Arial, serif;
  letter-spacing: 0.1px;
  text-align: left;
}

.menuitem_style {
  white-space: normal;
}

.otp_menuitem_style {
  white-space: normal;
}

.otp_menuitem_style:hover {
  background-color: #661c69;
  color: #ffffff;
}

.label_title span {
  color: #fd0000;
}

.error {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #fd0000;
  margin-top: 2px;
}

.backArrow {
  display: flex;
  width: 100%;
  height: 40px;
  gap: 10px;
  align-items: center;
}

@media only screen and (max-width: 1315px) {
  .wrapper > img {
    max-width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper > img {
    display: none;
  }
  .label_title {
    margin-bottom: 10px;
    color: #444444;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
  }
  .container {
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 26.5rem;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001c;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    padding: 2rem 2rem;
  }
  .backArrow {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .welcomeHeading {
    flex-direction: column;
  }
}