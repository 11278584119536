.wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001c;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  padding: 30px 44px;
  // max-width: 920px;
  // @media screen and (min-width: 1224px) {
  //   min-width: 100%;
  // }
}

.container {
  height: auto;
  margin: 20px;
  /* padding: 20px; */
  padding-right: 30px;
  background: #ffffff;
}

.container > label {
  font-weight: 700;
  font-size: 14px;
}

.containerCenter {
  display: flex;
  justify-content: flex-end;
  background-image: url('../images/otp-placeholder.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  /* justify-content: center; */
}
.outerContainer {
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21);
  border-radius: 6px;
  max-width: 960px;
  margin: 50px 0;
}
.fieldContainer {
  margin: 30px;
}

.welcomeText {
  font-family: 'TTNormsRegular', serif;
  font-style: normal;
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
  color: #000000;
}

.welcomeTextLineTwo {
  font-family: 'TTNormsBold', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  color: #000000;
}

.horizontalLineBreak {
  border: 1px solid #c5cee0;
  margin-top: 15px;
  margin-bottom: 20px;
  background-color: #c5cee0;
}

.boxMarginTop {
  margin-top: 20px;
}

.continueContainer {
  margin-top: 25px;
}

.btnAlign {
  display: flex;
  justify-content: space-between;
}
.continue_button {
  width: 194px;
  height: 44px;
  background: #661c69;
  border-radius: 56px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
}
.continue_button:hover {
  background: #661c69;
}
.continue_button:disabled {
  color: rgba(143, 155, 179, 0.5);
  background: #e4e9f2;
}

@media only screen and (min-width: 960px) {
  .horizontalLineBreak {
    width: 114%;
    position: relative;
    right: 31px;
  }
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
.middle_heading {
  // font: normal normal bold 16px/18px Arial;
  font-family: 'TTNormsRegular', Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.11px;
  margin: 0;
  color: #000000;
}

.alert_message {
  display: flex;
  border: 1px solid #ffaa00;
  color: #444444;
  padding: 10px;
  margin-top: 5px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 16px;
}

.middle_sub_heading {
  // font: normal normal bold 16px/18px Arial;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.11px;
  color: #e37617;
  text-align: center;
  margin: 10px 0 30px;
}

.label {
  margin: 0 0 4px;
  // font: normal normal 300 14px/16px Arial;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.1px;
  color: #000000;
}

.input {
  border-radius: 0;
  fieldset {
    border: 0.25px solid #555858;
  }
}

.sub_text_btn {
  text-align: left;
  // font: normal normal bold 15px/18px Arial;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.1px;
  color: #661c69;
  margin: 0px 0 0 0;
  height: auto;
  display: block;
  margin-right: auto;
  margin-left: 0;
}

.login_button{
  width: 100%;
  height: 40px;
  background: #661c69;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
}

.login_button:hover {
  background: #ffffff;
  color: #661c69;
  border: 1px solid #661c69;
}

.button_cancel {
  width: 100%;
  height: 40px;
  border: 1px solid #661C69;
  background: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #661c69;
  text-transform: none;
}

.button_cancel:hover {
  background: #661c69;
  border-color: #661c69;
  color: #ffffff;
}

.btn_wrapper {
  margin-top: 140px;
}

.btn_wrapper_pass {
  display: flex;
  margin-top: 20px;
  gap: 15px;
  flex-wrap: nowrap;
}

.imgRegister {
  width: 249px;
  height: 267px;
}

.right_wrapper {
  padding: 0 20px;
  margin-top: 15px;
  h4 {
    // font: normal normal bold 15px/18px Arial;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.11px;
    color: #000000;
    margin-bottom: 0;
    width: 250px;
  }
  .privacy {
    width: 192px;
    margin-bottom: 30px;
    button {
      height: auto;
      padding: 0;
      line-height: 0;
      color: #e37617;
    }
  }
  .need_help {
    padding: 0;
    margin: 0;
  }
}

.bottom_image {
  position: absolute;
  width: 288px;
  height: 290px;
  bottom: 0;
  left: 0;
}

.info_otp{
    color: #E37617;
    margin: 5px 0 0;
}

.otp_text{
    margin: 20px 0 0;
}

.canvasCustom{
  margin: 30px 0 10px;
  div:nth-of-type(1){
    display: flex;
  }
  canvas{
    width: 85%;
  }
  a{
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .forgotAcctBox {
    display: inline-block;
    margin-bottom: 20px;
  }
  .container {
    height: auto;
    margin: 10px;
    padding: 0 18px;
    padding-top: 30px;
    background: #ffffff;
  }
  .welcomeText {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }

  .welcomeTextLineTwo {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    color: #000000;
  }
  .boxMarginTop {
    margin-top: 35px;
  }
}